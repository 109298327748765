var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"depositPage bgfff",staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"app h5MaxWidth"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"main"},[_c('div',{staticClass:"deposit forms"},[_c('form',{attrs:{"id":"login","action":"#","method":"post","onsubmit":""}},[_c('ul',[_c('li',[_vm._m(2),_c('div',{staticClass:"inputArea display alignCenter"},[_vm._m(3),_c('div',{staticClass:"trueInpt display alignCenter flex1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.choiceNum),expression:"choiceNum"}],staticClass:"amount fillArea flex1",attrs:{"type":"number","name":"amount","id":"amount","placeholder":"Deposit Amount","maxlength":"10"},domProps:{"value":(_vm.choiceNum)},on:{"input":function($event){if($event.target.composing)return;_vm.choiceNum=$event.target.value}}})])])]),_c('li',[_vm._m(4),_c('div',{staticClass:"quickAmount",attrs:{"id":"quickAmount"}},[_c('ol',{staticClass:"display alignCenter"},_vm._l((_vm.numdata),function(item){return _c('li',{key:item.list1,class:item.list1 === _vm.choiceNum ? 'activate' : '',on:{"click":function($event){return _vm.changeNum(item)}}},[_c('div',{staticClass:"radius7PX"},[_c('em',{staticClass:"currency"},[_vm._v("₹")]),_vm._v(_vm._s(item.list1)+" ")])])}),0)])]),_c('li',{staticClass:"channel"},[_vm._m(5),_c('div',{staticClass:"paywaylist",attrs:{"id":"paywaylist"}},_vm._l((_vm.PaymentList),function(item){return _c('div',{key:item.id,class:_vm.choicePayId === item.id
                      ? 'radiogroup clear checked'
                      : 'radiogroup clear',staticStyle:{"margin-top":"10px","border":"2px solid #fff"},on:{"click":function($event){return _vm.changePay(item)}}},[_c('label',{staticClass:"radius7PX",staticStyle:{"margin-top":"0"}},[_c('p',[_vm._v(_vm._s(item.name))])])])}),0)])]),_c('div',{staticClass:"actionBar display alignCenter alignStretch justifyEnd"},[_c('div',{staticClass:"drawmoneyBtn clickBtn radius50P display alignCenter justifyCenter",on:{"click":function($event){return _vm.goDoposit()}}},[_vm._m(6)])])])]),_c('div',{staticClass:"tipsBox"},[_c('div',{staticClass:"tips",attrs:{"id":"tips"}},[_vm._m(7),_c('div',{staticClass:"explain",domProps:{"innerHTML":_vm._s(_vm.rule)}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"isScrollTo"},[_c('div',{staticClass:"header h5MaxWidth clear display alignCenter",staticStyle:{"clip-path":"none"}},[_c('div',{staticClass:"back display alignCenter justifyCenter",attrs:{"onclick":"window.history.go(-1)"}},[_c('i',{staticClass:"iconfont icon-arrow-left"})]),_c('h2',{staticClass:"flex1"},[_vm._v("Deposit")]),_c('div',{staticClass:"navskip",attrs:{"id":"sideMenu"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topRegion",staticStyle:{"padding-top":"30px"}},[_c('div',{staticClass:"statistics"},[_c('ul',{staticClass:"display alignCenter"},[_c('li',{staticClass:"balance"},[_c('p',[_c('em',{staticClass:"currency"},[_vm._v("₹")]),_vm._v("0.00")]),_c('span',[_vm._v("Account balance")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formTitle display alignCenter justifyDetween"},[_c('div',[_c('h3',[_vm._v("Deposit amount")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftSideBox display alignCenter"},[_c('i',{staticClass:"i_inputIcon iconfont icon-coin-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formTitle display alignCenter justifyDetween"},[_c('div',[_c('h3',[_vm._v("Quick amount")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formTitle display alignCenter justifyDetween"},[_c('div',[_c('h3',[_vm._v("Channel")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To Deposit "),_c('i',{staticClass:"iconfont icon-arrow-right-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleBar display alignCenter justifyDetween"},[_c('div',[_c('h3',[_vm._v("Explain")])])])
}]

export { render, staticRenderFns }