<template>
  <div class="depositPage bgfff" style="background-color: #fff">
    <div class="app h5MaxWidth">
      <header class="isScrollTo">
        <div
          class="header h5MaxWidth clear display alignCenter"
          style="clip-path: none"
        >
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">Deposit</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>
      <div class="topRegion" style="padding-top: 30px">
        <div class="statistics">
          <ul class="display alignCenter">
            <li class="balance">
              <p><em class="currency">₹</em>0.00</p>
              <span>Account balance</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="main">
        <div class="deposit forms">
          <form id="login" action="#" method="post" onsubmit="">
            <ul>
              <li>
                <div class="formTitle display alignCenter justifyDetween">
                  <div>
                    <h3>Deposit amount</h3>
                  </div>
                </div>
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-coin-1"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      class="amount fillArea flex1"
                      placeholder="Deposit Amount"
                      maxlength="10"
                      v-model="choiceNum"
                    />
                  </div>
                </div>
              </li>

              <li>
                <div class="formTitle display alignCenter justifyDetween">
                  <div>
                    <h3>Quick amount</h3>
                  </div>
                </div>
                <div class="quickAmount" id="quickAmount">
                  <ol class="display alignCenter">
                    <li
                      v-for="item in numdata"
                      :key="item.list1"
                      @click="changeNum(item)"
                      :class="item.list1 === choiceNum ? 'activate' : ''"
                    >
                      <div class="radius7PX">
                        <em class="currency">₹</em>{{ item.list1 }}
                      </div>
                    </li>
                  </ol>
                </div>
              </li>

              <li class="channel">
                <div class="formTitle display alignCenter justifyDetween">
                  <div>
                    <h3>Channel</h3>
                  </div>
                </div>
                <div class="paywaylist" id="paywaylist">
                  <div
                    :class="
                      choicePayId === item.id
                        ? 'radiogroup clear checked'
                        : 'radiogroup clear'
                    "
                    style="margin-top: 10px; border: 2px solid #fff"
                    v-for="item in PaymentList"
                    :key="item.id"
                    @click="changePay(item)"
                  >
                    <label class="radius7PX" style="margin-top: 0"
                      ><p>{{ item.name }}</p>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
            <div class="actionBar display alignCenter alignStretch justifyEnd">
              <div
                class="drawmoneyBtn clickBtn radius50P display alignCenter justifyCenter"
                @click="goDoposit()"
              >
                <p>To Deposit <i class="iconfont icon-arrow-right-3"></i></p>
              </div>
            </div>
          </form>
        </div>

        <div class="tipsBox">
          <div id="tips" class="tips">
            <div class="titleBar display alignCenter justifyDetween">
              <div>
                <h3>Explain</h3>
              </div>
            </div>
            <div class="explain" v-html="rule"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      numdata: [
        {
          list1: "500",
        },
        {
          list1: "1000",
        },
        {
          list1: "600",
        },
        {
          list1: "900",
        },
        {
          list1: "800",
        },
        {
          list1: "2000",
        },
        {
          list1: "300",
        },
        {
          list1: "700",
        },
        {
          list1: "10000",
        },
      ],
      choiceNum: "500",
      PaymentList: [],
      choicePayId: "",
      rule: "",
    };
  },
  components: {},
  methods: {
    changeNum(item) {
      this.choiceNum = item.list1;
    },
    changePay(item) {
      this.choicePayId = item.id;
    },
    goDoposit() {
      //充值
      this.getAjax(
        "api/recharge/index",
        {
          uid: this.getItem("uid"),
          payType: this.choicePayId,
          amount: this.choiceNum,
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          window.open(res.data);
        } else {
          this.tips(res.msg, "danger");
        }
      });
    },
  },
  mounted() {
    this.uid = this.getItem("uid");
    this.getAjax("api/recharge/index?uid=" + this.uid, {}, "get").then(
      (res) => {
        this.PaymentList = [];
        Object.keys(res.PaymentList).forEach((e) => {
          this.PaymentList.push({
            id: e,
            name: res.PaymentList[e],
          });
        });
        this.choicePayId = this.PaymentList[0].id;
        this.rule = res.rule.content;
      }
    );
    
  },
};
</script>

<style scoped lang="less">
.checked {
  border: 2px solid #df2144 !important;
  border-radius: 5px;
  background: #fff1f3;
}
</style>
